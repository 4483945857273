<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-6">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Expiry Report
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Expiry Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-6 d-flex justify-content-end pr-2 pb-2">
        <div class="bg-theam-secondary d-flex m-2 p-1">
          <div class="report-tool-icon mr-3 ml-1">
            <i class="fas fa-print" title="Print" @click="print"></i>
          </div>
          <!-- <div class="report-tool-icon mr-1">
            <i class="fas fa-download" title="Download"></i>
          </div> -->
        </div>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-12 d-block mr-0 pr-0">
          <select
            v-model="expiry_params.pageIndex"
            @change="selectTotalData"
            class="mr-2 mg-t-5"
          >
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="">All</option>
          </select>
          <select
            class="mr-2 mg-t-5"
            v-model="expiry_params.fiscal_year"
            @change="getFilteredDataWithoutDates"
          >
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}
            </option>
          </select>
          <select
            v-model="expiry_params.branch"
            class="filter-input mr-2 mg-t-5"
            @change="selectBranch"
          >
            <option value="">All Branch</option>
            <option
              v-for="(branch, index) in branches"
              :key="index"
              :value="branch.id"
            >
              {{ branch.name }}
            </option>
          </select>
          <select
            v-model="expiry_params.status"
            class="filter-input mr-2 mg-t-5"
            @change="selectStatus"
          >
            <option value>All Records</option>
            <option value="1">Expired</option>
            <option value="2">Expiring</option>
          </select>

          <SearchDayMonthComponent
            @clicked="monthDayClicked"
          ></SearchDayMonthComponent>
          <div class="d-inline-block mr-2">
            <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
            <input
              type="date"
              v-model="expiry_params.from_date"
              placeholder="From"
              class="filter-input mr-1 mg-t-5"
              @change="selectFromDate"
            />
            <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
            <input
              type="date"
              v-model="expiry_params.to_date"
              placeholder="To"
              class="mr-1 mg-t-5 filter-input"
              @change="selectEndDate"
            />
          </div>
          <input
            type="text"
            v-model="expiry_params.searched"
            class="filter-input mr-3 mg-t-5 float-right"
            placeholder="Search..."
            @keyup="searchKeyword"
          />
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
      <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Expired Item: {{ expiredCount }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Expiring Item: {{ expiryCount }},</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Total Expired Item Value: NPR {{ parseDigitForSlip(expiredValue) }}
        </span>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-10p">Item Id</th>
              <th class="wd-20p">Item Name</th>
              <th class="wd-15p">Category</th>
              <th class="wd-10p">Purchase ID</th>
              <th class="wd-15p">Quantity</th>
              <th class="wd-15p">Expiry Date</th>
              <th class="wd-10p text-center">Validity</th>
            </tr>
          </thead>
          <tbody v-if="!loading && expiryWiseDatas.length > 0">
            <tr v-for="(item, index) in expiryWiseDatas" :key="index">
              <th scope="row" class="table-start-item">
                {{ pageSerialNo + index }}
              </th>
              <th>{{ item.item_code }}</th>
              <td>
                {{ item.name }}
              </td>
              <td>{{ item.item_group }}</td>
              <td>{{ item.item_prefix }}{{ item.purchase_id }}</td>
              <td>{{ item.available }} {{ item.title }}</td>
              <td>{{ item.expiry_date }}</td>
              <td class="text-center">
                <span
                  class="tx-danger font-s"
                  v-if="item.validity_status == 1"
                  >{{ item.validity }}</span
                >
                <span
                  class="tx-warning font-s"
                  v-else-if="item.validity_status == 2"
                >
                  {{ item.validity }}</span
                >
                <span class="tx-success font-s" v-else>{{
                  item.validity
                }}</span>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <tr>
              <td colspan="10" class="tx-center">{{ errorMessage }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && expiryWiseDatas.length == 0">
            <tr>
              <td colspan="11" style="text-align: center">No records found.</td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td colspan="11" style="text-align: center">Loading.....</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Paginate
      v-model="page"
      :pageCount="totalPageCount"
      :containerClass="'pagination'"
      :clickHandler="clickCallback"
      v-if="totalPageCount > 1"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchDayMonthComponent from "../../../../shared/SearchDayMonthComponent";
import Services from "./Services/Services";
import Paginate from "vuejs-paginate";
import _ from "lodash";

export default {
  components: {
    SearchDayMonthComponent,
    Paginate,
  },
  data() {
    return {
      sessionYears: ["2021-22"],
      route: this.$route.path,
      branches: [],
      expiryCount: 0,
      expiredCount: 0,
      expiredValue: 0,
      expiry_params: {
        status: "",
        pageIndex: 50,
        branch: "",
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        from_date: "",
        to_date: "",
        searched: "",
        offset: 0,
        fiscal_year: "2021-22",
      },
      loading: false,
      error: false,
      errorMessage: "",
      totalPageCount: 0,
      pageReload: "",
      pageSerialNo: 1,
      page: 1,
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "dataLists2",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("expiryReports", ["expiryWiseDatas", "expiryWiseAttributes"]),
  },
  beforeMount() {
    this.getFilteredDataWithoutDates();
    this.pageReload = true;
    Services.getUserAccessBranches()
      .then((response) => {
        this.branches = response.data.data;
        // this.sessionYears = response.data.data.fiscalYears;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    monthDayClicked(value) {
      if (value) {
        this.expiry_params.from_date = "";
        this.expiry_params.to_date = "";
        this.expiry_params.day = value.day;
        this.expiry_params.month = value.month;
        this.expiry_params.year = value.year;
      }
      //make api call
      this.getFilteredData();
    },
    print(){
      window.print();
    },
    // pagination clicked
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.filterParams.pageIndex + 1;
      }
      this.offset = (page - 1) * this.filterParams.pageIndex;
      this.getFilteredDataWithoutDates();
    },
    selectTotalData() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectBranch() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectStatus() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectFromDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    selectEndDate() {
      this.page = 1;
      this.pageSerialNo = 1;
      this.offset = 0;
      this.getFilteredDataWithoutDates();
    },
    searchKeyword: _.debounce(function () {
      this.page = 1;
      this.pageSerialNo = 1;
      this.expiry_params.offset = 0;
      this.getFilteredDataWithoutDates();
    }, 1000),

    getFilteredData() {
      this.loading = true;
      let params = {
        pageIndex: this.expiry_params.pageIndex,
        branch: this.expiry_params.branch,
        status: this.expiry_params.status,
        today: this.expiry_params.day,
        month: this.expiry_params.month + 1,
        year: this.expiry_params.year,
        from_date: this.expiry_params.from_date,
        to_date: this.expiry_params.to_date,
        searched: this.expiry_params.searched,
        page_reload: this.pageReload,
        offset: this.expiry_params.offset,
      };
      this.ajaxRequest(params);
    },
    // without today/month section
    getFilteredDataWithoutDates() {
      this.loading = true;
      let params = {
        pageIndex: this.expiry_params.pageIndex,
        branch: this.expiry_params.branch,
        status: this.expiry_params.status,
        today: "",
        month: "",
        year: this.expiry_params.year,
        from_date: this.expiry_params.from_date,
        to_date: this.expiry_params.to_date,
        searched: this.expiry_params.searched,
        page_reload: this.pageReload,
        offset: this.expiry_params.offset,
      };
      this.ajaxRequest(params);
    },

    ajaxRequest(params) {
      this.error = false;
      Services.getExpiryWiseFilterDatas(params)
        .then((res) => {
          this.$store.commit(
            "expiryReports/setExpiryWiseAttributes",
            res.data.data.attributes
          );
          this.$store.commit(
            "expiryReports/setExpiryWiseDatas",
            res.data.data.data
          );
          // this.purchaseOrders = res.data.data.purchaseOrder;
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.expiryCount = res.data.data.expiring_item;
          this.expiredCount = res.data.data.expired_item;
          this.expiredValue = res.data.data.expired_value;
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          if (
            !Object.prototype.hasOwnProperty.call(
              error,
              "response.data.error_message"
            )
          ) {
            if (error.response.data.error_message == "Access Denied.") {
              this.errorMessage = "Access Denied !!!";
              // this.$router.push({ name: "access-denied" });
            } else {
              this.errorMessage = "Error fetching data from the server.";
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  destroyed() {
    this.$store.dispatch("destroyEvent");
  },
};
</script>