<template>
  <div class="d-inline-block mg-t-5 mg-l-5">
    <ul class="mr-2 d-inline-block" v-if="showDay">
      <li>
        <a href="javascript:;" @click="clickedPrevDay">
          <i class="fas fa-arrow-left"></i>
        </a>
      </li>
      <li>
        <a href="javascript:;" @click="toDay">{{daySectionMonthName}}</a>
      </li>
      <li>
        <a href="javascript:;" @click="clickedNextDay">
          <i class="fas fa-arrow-right"></i>
        </a>
      </li>
    </ul>
    <ul class="mr-2 d-inline-block" v-if="showMonth">
      <li>
        <a href="javascript:;" @click="clickedPrevMonth">
          <i class="fas fa-arrow-left"></i>
        </a>
      </li>
      <li>
        <a href="javascript:;" @click="thisMonth">{{monthName}}</a>
      </li>
      <li>
        <a href="javascript:;" @click="clickedNextMonth">
          <i class="fas fa-arrow-right"></i>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import preDefinedMonths from "../store/constants/months";
import _ from "lodash";
export default {
  props: {
    showMonth: {
      type: Boolean,
      default: true,
    },
    showDay: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      prevDay: 0,
      nextDay: 0,
      maxMonthDay: 0,
      daySectionMonthName: "Today",
      currentYear: new Date().getFullYear(),
      currentDay: new Date().getDate(),
      currentMonth: new Date().getMonth(),
      todaysMonth: new Date().getMonth(),
      monthName: "",
    };
  },
  mounted() {
    this.calculateMaxMonthDay();
  },
  methods: {
    calculateMaxMonthDay() {
      let y = this.currentYear;
      let m = this.currentMonth;
      var d = new Date(y, m + 1, 0);
      this.maxMonthDay = d.getDate();
      this.monthName = preDefinedMonths.smallMonths[m]["name"];
    },

    calculateMaxMonthDayFromDaySection() {
      let y = this.currentYear;
      let m = this.todaysMonth;
      var d = new Date(y, m + 1, 0);
      this.maxMonthDay = d.getDate();
    },
    getMonthName(m) {
      let monthName = preDefinedMonths.smallMonths[m]["name"];
      if (
        this.currentDay == new Date().getDate() &&
        this.currentMonth == new Date().getMonth() &&
        this.currentYear == new Date().getFullYear()
      ) {
        this.daySectionMonthName = "Today";
      } else {
        this.daySectionMonthName = `${monthName}-${this.currentDay}`;
      }
    },
    //################ previous day #################
    clickedPrevDay: _.debounce(function () {
      this.prevDay = this.currentDay - 1;
      //if the preDay is less than 1 , changing the month
      if (this.prevDay == 0) {
        let changedMonth = this.todaysMonth - 1;
        // if the months is less than january, changing the year
        if (changedMonth < 0) {
          this.todaysMonth = 11;
          this.currentYear = this.currentYear - 1;
          this.calculateMaxMonthDayFromDaySection();
          this.currentDay = this.maxMonthDay;
        } else {
          this.todaysMonth -= 1;
          this.calculateMaxMonthDayFromDaySection();
          this.currentDay = this.maxMonthDay;
        }
      } else {
        this.currentDay = this.prevDay;
        this.calculateMaxMonthDayFromDaySection();
      }

      this.getMonthName(this.todaysMonth);
      let m = new Date().getMonth();
      this.monthName = preDefinedMonths.smallMonths[m]["name"];
      this.currentMonth = m;
      let data = {
        day: this.currentDay,
        month: this.todaysMonth,
        year: this.currentYear,
      };
      this.$emit("clicked", data);
    }, 500),

    //################ Today ##################
    toDay: _.debounce(function () {
      //get today data
      let data = {
        day: new Date().getDate(),
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      };
      this.$emit("clicked", data);
    }, 500),

    //################ Next Day ##################
    clickedNextDay: _.debounce(function () {
      this.nextDay = this.currentDay + 1;
      //if the preDay is less than 1 , changing the month
      if (this.nextDay > this.maxMonthDay) {
        let changedMonth = this.todaysMonth + 1;
        // if the months is less than january, changing the year
        if (changedMonth > 11) {
          this.todaysMonth = 0;
          this.currentYear = this.currentYear + 1;
          this.calculateMaxMonthDayFromDaySection();
          this.currentDay = this.maxMonthDay;
        } else {
          this.todaysMonth += 1;
          this.currentDay = 1;
          this.calculateMaxMonthDayFromDaySection();
        }
      } else {
        this.currentDay = this.nextDay;
      }
      this.getMonthName(this.todaysMonth);
      let data = {
        day: this.currentDay,
        month: this.todaysMonth,
        year: this.currentYear,
      };
      //showing the current month
      let m = new Date().getMonth();
      this.currentMonth = m;

      this.monthName = preDefinedMonths.smallMonths[m]["name"];
      this.$emit("clicked", data);
    }, 500),

    //################ Previous Month ##################
    clickedPrevMonth: _.debounce(function () {
      let changedMonth = this.currentMonth - 1;
      if (changedMonth < 0) {
        // making the default month decemeber
        this.currentMonth = 11;
        this.currentYear = this.currentYear - 1;
        // this.calculateMaxMonthDay();
      } else {
        //decreasing the month by 1
        this.currentMonth -= 1;
      }
      this.calculateMaxMonthDay();
      this.currentDay = new Date().getDate();
      this.daySectionMonthName = "Today";

      let data = {
        day: "",
        month: this.currentMonth,
        year: this.currentYear,
      };
      this.$emit("clicked", data);
    }, 500),

    //################ this Month ##################
    thisMonth: _.debounce(function () {
      let data = {
        day: "",
        month: this.currentMonth,
        year: this.currentYear,
      };

      this.daySectionMonthName = "Today";
      this.$emit("clicked", data);
    }, 500),

    //################ Next Month ##################
    clickedNextMonth: _.debounce(function () {
      let changedMonth = this.currentMonth + 1;
      if (changedMonth > 11) {
        // making the default month decemeber
        this.currentMonth = 0;
        this.currentYear = this.currentYear + 1;
      } else {
        //increasing the month by 1
        this.currentMonth += 1;
      }
      this.calculateMaxMonthDay();
      this.currentDay = new Date().getDate();
      this.daySectionMonthName = "Today";

      let data = {
        day: "",
        month: this.currentMonth,
        year: this.currentYear,
      };
      this.$emit("clicked", data);
    }, 500),
  },
};
</script>