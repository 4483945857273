class preDefinedMonths{
     largeMonths = [
        {
            'id':0,
            'name': 'January',
        },
        {
            'id':1,
            'name': 'February'
        },
        {
           'id':2,
            'name': 'March'
        },
        {
            'id':3,
            'name': 'April'
        },
        {
            'id':4,
            'name': 'May'
        },
        {
            'id':5,
            'name': 'June'
        },
        {
            'id':6,
            'name': 'July'
        },
        {
            'id':7,
            'name': 'August'
        },
        {
            'id':8,
            'name': 'September'
        },
        {
            'id':9,
            'name': 'October'
        },
        {
            'id':10,
            'name': 'November'
        },
        {
            'id':11,
            'name': 'December'
        },
    
    ];
    smallMonths = [
        {
            'id':0,
            'name': 'Jan'
        },
        {
            'id':1,
            'name': 'Feb'
        },
        {
           'id':2,
            'name': 'Mar'
        },
        {
            'id':3,
            'name': 'Apr'
        },
        {
            'id':4,
            'name': 'May'
        },
        {
            'id':5,
            'name': 'Jun'
        },
        {
            'id':6,
            'name': 'Jul'
        },
        {
            'id':7,
            'name': 'Aug'
        },
        {
            'id':8,
            'name': 'Sept'
        },
        {
            'id':9,
            'name': 'Oct'
        },
        {
            'id':10,
            'name': 'Nov'
        },
        {
            'id':11,
            'name': 'Dec'
        },
    
    ]
}

export default new preDefinedMonths();
